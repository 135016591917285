import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@flink-legacy/shared/services/notification.service';
import { environment } from '@bling-fe/shared/env';
import {
  HttpErrorHandlerStrategy,
  matchesStatus,
} from './http-error-handler-strategy';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import * as Sentry from '@sentry/angular-ivy';
import {
  BadRequest,
  Forbidden,
  InternalServerError,
  NoResponse,
  NotFound,
  Offline,
  ServiceUnavailable,
  Unauthenticated,
  Unknown,
  UnprocessableEntity,
} from './customized-errors';

type HandledHttpErrorTypes =
  | 'bad_request'
  | 'internal_server_error'
  | 'unauthenticated'
  | 'forbidden'
  | 'not_found'
  | 'service_unavailable'
  | 'no_response'
  | 'offline'
  | 'unprocessable_entity'
  | 'unknown';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private httpErrorHandlerStrategies: Array<HttpErrorHandlerStrategy> = [];

  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.httpErrorHandlerStrategies.push(
      {
        matches: e => matchesStatus(e, 400),
        handle: e => this.errorHandler('bad_request', e.url),
      },
      // example case, when user create new repair message
      // but there aren't any Rental objects
      {
        matches: e => matchesStatus(e, 422),
        handle: e => this.errorHandler('unprocessable_entity', e.url),
      },
      {
        matches: e => matchesStatus(e, 500),
        handle: e => this.errorHandler('internal_server_error', e.url),
      },
      {
        matches: e => matchesStatus(e, 401),
        handle: e => this.errorHandler('unauthenticated', e.url),
      },
      {
        matches: e => matchesStatus(e, 403),
        handle: e => {
          this.errorHandler('forbidden', e.url);
        },
      },
      {
        matches: e => matchesStatus(e, 404),
        handle: e => this.errorHandler('not_found', e.url),
      },
      {
        matches: e => matchesStatus(e, 504),
        handle: e => this.errorHandler('service_unavailable', e.url),
      },
      {
        matches: e => matchesStatus(e, 0),
        handle: e => this.errorHandler('no_response', e.url),
      },
      {
        matches: __ => !navigator.onLine,
        handle: e => this.errorHandler('offline', e.url),
      }
    );
  }

  handleError(error) {
    // eslint-disable-next-line no-console
    console.error('handleError:', error);
    this.notificationService.error(_('ERROR.GLOBAL.UNKNOWN'));
  }

  handleHttpError(httpErrorResponse: HttpErrorResponse) {
    if (!(httpErrorResponse instanceof HttpErrorResponse)) {
      throw new Error(_('ERROR.GLOBAL.NOT_HTTP_ERROR_RESPONSE'));
    }
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log('handleHttpError:', httpErrorResponse);
    }
    const errorHandlingStrategy = this.httpErrorHandlerStrategies.find(s =>
      s.matches(httpErrorResponse)
    );

    if (errorHandlingStrategy) {
      errorHandlingStrategy.handle(httpErrorResponse);
    } else {
      this.errorHandler('unknown', httpErrorResponse.url);
    }
  }

  private errorHandler(errorType: HandledHttpErrorTypes, requestedUrl: string) {
    Sentry.captureException(getHttpRequestException(errorType, requestedUrl), {
      tags: {
        type: errorType,
      },
    });

    this.notificationService.error(`ERROR.GLOBAL.${errorType.toUpperCase()}`);
  }
}

const getHttpRequestException = (
  httpErrorResponse: HandledHttpErrorTypes,
  msg: string
) => {
  switch (httpErrorResponse) {
    case 'bad_request':
      return new BadRequest(msg);
    case 'internal_server_error':
      return new InternalServerError(msg);
    case 'forbidden':
      return new Forbidden(msg);
    case 'no_response':
      return new NoResponse(msg);
    case 'not_found':
      return new NotFound(msg);
    case 'offline':
      return new Offline(msg);
    case 'service_unavailable':
      return new ServiceUnavailable(msg);
    case 'unauthenticated':
      return new Unauthenticated(msg);
    case 'unprocessable_entity':
      return new UnprocessableEntity(msg);
    case 'unknown':
      return new Unknown(msg);
  }
};

// translations
_('ERROR.GLOBAL.UNKNOWN');
_('ERROR.GLOBAL.BAD_REQUEST');
_('ERROR.GLOBAL.INTERNAL_SERVER_ERROR');
_('ERROR.GLOBAL.UNAUTHENTICATED');
_('ERROR.GLOBAL.FORBIDDEN');
_('ERROR.GLOBAL.NOT_FOUND');
_('ERROR.GLOBAL.SERVICE_UNAVAILABLE');
_('ERROR.GLOBAL.NO_RESPONSE');
_('ERROR.GLOBAL.OFFLINE');
_('ERROR.GLOBAL.UNPROCESSABLE_ENTITY');
