import { NotificationService } from './../../../services/notification.service';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export type SupportedMimeTypes = 'pdf' | 'xls' | 'doc';

export const mimeTypes: Record<string, SupportedMimeTypes> = {
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'doc',
  'application/msword': 'doc',
};

@Component({
  selector: 'fl-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent {
  @Input() validFileTypes = Object.keys(mimeTypes).join(',');
  @Output() fileAdd: EventEmitter<File> = new EventEmitter();
  @ViewChild('fileInput') fileInput;

  constructor(
    private toast: ToastController,
    private translate: TranslateService,
    private notification: NotificationService
  ) {}

  fileChanged($event) {
    const files: File[] = Array.from($event.target.files);
    files.forEach(file => {
      // convert to megabytes
      const fileSize = file.size / 1048576;

      if (this.validFileTypes.includes(file.type) && fileSize <= 10) {
        this.fileAdd.emit(file);
      } else {
        if (fileSize > 10) {
          this.notification.error(_('ERROR.WRONG_FILE_SIZE'));
        }
        if (!this.validFileTypes.includes(file.type)) {
          this.notification.error(_('ERROR.WRONG_FILE_FORMAT'));
        }
      }

      $event.target.value = null;
    });
  }

  public openUpload() {
    // hack – trigger file input select file
    this.fileInput.nativeElement.click();
  }
}
