import { Component, OnInit, inject } from '@angular/core';
import { Group } from '@flink-legacy/core/declarations/group.interface';
import { Settlement } from '@flink-legacy/core/declarations/settlement.interface';
import { UserState } from '@flink-legacy/core/states/user-state/user.reducers';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { SelectOption } from '@flink-legacy/shared/components/forms/select/select.component';
import { GroupService } from '@flink-legacy/shared/services/group.service';
import { LoadingService } from '@flink-legacy/shared/services/loading.service';
import { SettlementService } from '@flink-legacy/shared/services/settlement.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { first, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { IonicModule } from '@ionic/angular';
import { SelectModalBaseComponent } from '../select-modal-base/select-modal-base.component';
import { AsyncPipe, NgFor } from '@angular/common';
import { ModalAbstractComponent } from '@bling-fe/shared/modals/base-modal';

_('COMMON.POST_SCOPE.ALL');

export interface SettlementScope {
  scope: 'settlement';
  settlement_id: number;
}

export interface GroupScope {
  scope: 'group';
  group_id: number;
}

export interface AllScope {
  scope: 'all';
}

export type PostScope = SettlementScope | GroupScope | AllScope;

export const postScopeAllOption: (
  translateService: TranslateService
) => SelectOption<AllScope> = (translateService: TranslateService) => ({
  value: { scope: 'all' },
  label: translateService.instant('COMMON.POST_SCOPE.ALL'),
  image:
    'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
});

@Component({
  selector: 'fl-select-post-scope-modal',
  templateUrl: './select-post-scope-modal.component.html',
  styleUrls: ['./select-post-scope-modal.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TranslateModule,
    SelectModalBaseComponent,
    NgFor,
    AsyncPipe,
  ],
  providers: [
    {
      provide: ModalAbstractComponent,
      useExisting: SelectPostScopeModalComponent,
    },
  ],
})
export class SelectPostScopeModalComponent
  extends ModalAbstractComponent<{ value: PostScope }, SelectOption<PostScope>>
  implements OnInit
{
  scopeOptions$: Observable<SelectOption<PostScope>[]>;
  searchFilter = new BehaviorSubject<string>('');
  selectedOption: SelectOption<PostScope>;

  private userStore = inject(Store<UserState>);
  private groupService = inject(GroupService);
  private translate = inject(TranslateService);
  private loading = inject(LoadingService);
  private settlementService = inject(SettlementService);

  ngOnInit(): void {
    this.loading.show();
    this.setSelectOptions();
    this.settlementService.fetch({ fetch_all: true });
  }

  private setSelectOptions() {
    const settlements$ = this.settlementService.data$.pipe(
      skipWhile(r => r.state === 'loading'),
      map(r => r.data.items as Settlement[] | undefined),
      first()
    );
    const mySettlement$ = this.userStore.pipe(
      select(getCurrentUser),
      first(),
      map(u => [u.settlement])
    );

    const myGroups$ = this.groupService.myGroups$.pipe(
      skipWhile(r => r.state === 'loading'),
      map(r => r.data as Group[] | undefined),
      first()
    );

    const staffOrAdmin$ = this.userStore.pipe(
      select(getCurrentUser),
      first(),
      map(u => u.super_admin || u.admin || u.staff)
    );

    this.scopeOptions$ = forkJoin([
      settlements$,
      mySettlement$,
      myGroups$,
      staffOrAdmin$,
    ]).pipe(
      // fill in options
      map(([settlements, mySettlement, myGroups, staffOrAdmin]) => {
        if (staffOrAdmin) {
          return this.convertToSelectOptions(settlements, myGroups);
        } else {
          return this.convertToSelectOptions(mySettlement, myGroups);
        }
      }),
      tap(() => this.loading.dismiss()),
      // set selected option
      tap(
        options =>
          (this.selectedOption = options.find(o =>
            isEqual(o.value, this.props.value)
          ))
      ),
      // filter based on search
      switchMap(options =>
        this.searchFilter.pipe(
          map(search =>
            options.filter(o =>
              o.label.toLowerCase().includes(search.toLowerCase())
            )
          )
        )
      )
    );

    this.groupService.fetchMyGroups();
  }

  private convertToSelectOptions(settlements: Settlement[], myGroups: Group[]) {
    const options: SelectOption<PostScope>[] = [];
    const fallbackImg =
      'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y';

    options.push(postScopeAllOption(this.translate));

    const settlementOptions = settlements?.map(settlement => ({
      value: {
        scope: 'settlement' as 'settlement',
        settlement_id: settlement.id,
      },
      label: settlement.title,
      image: settlement.picture_url ?? fallbackImg,
    }));

    options.push(...(settlementOptions ?? []));

    const groupOptions = myGroups?.map(g => ({
      value: {
        scope: 'group' as 'group',
        group_id: g.id,
      },
      label: g.title,
      image: g.picture_url ?? fallbackImg,
    }));

    options.push(...(groupOptions ?? []));

    return options;
  }
}
