import { Component, Input, OnDestroy, inject } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MenuItem } from '../main-menu/main-menu.component';
import {
  from,
  fromEventPattern,
  map,
  mergeMap,
  Observable,
  of,
  startWith,
  Subject,
  tap,
} from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { TextZoom } from '@capacitor/text-zoom';
import { Store } from '@ngrx/store';
import {
  getCommunityNotificationCount,
  getServicesNotificationCount,
} from '@flink-legacy/core/states/message-notifications-state/message-notifications.selectors';

@Component({
  selector: 'fl-tabs-navigation',
  templateUrl: './tabs-navigation.component.html',
  styleUrls: ['./tabs-navigation.component.scss'],
})
export class TabsNavigationComponent implements OnDestroy {
  @Input() showMenuToggle = false;
  @Input() hide = false;
  private store = inject(Store);
  hideLabelsIos$: Observable<boolean> = this.hideLabelsIos();
  communityNotifications$ = this.store.select(getCommunityNotificationCount);
  serviceNotifications$ = this.store.select(getServicesNotificationCount);

  menuItems: MenuItem[] = [
    {
      label: _('PAGES.TABS.PINNWAND'),
      url: '/home',
      icon: 'push-pin',
    },
    {
      label: _('PAGES.TABS.CALENDAR'),
      url: '/calendar',
      icon: 'calendar-blank',
    },
    {
      label: _('PAGES.TABS.COMMUNITY'),
      url: '/community',
      icon: 'groups',
      notificationCount$: this.communityNotifications$,
    },
    {
      label: _('PAGES.TABS.MY_TENENT'),
      url: '/my-tenant',
      icon: 'services',
      notificationCount$: this.serviceNotifications$,
    },
    {
      label: _('PAGES.TABS.PROFILE'),
      url: '/my-profile',
      icon: 'profile',
    },
  ];

  private ngUnsubscribe = new Subject<void>();

  constructor(private menuController: MenuController) {}

  onNavigate() {
    this.menuController.close();
  }

  toggleMenu() {
    this.menuController.toggle();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /** Hack for iOS:
   * ios doesn't set the `initial` value of `font-size` in the webview correctly
   * from the system settings based on text zoom.
   * This means the mediaQuery in css (max-width: 20fr) doesn't work there
   * because for mediaqueries the max-width is calculated from the
   * `initial` font-size & not the one in `html` tag.
   */
  private hideLabelsIos(): Observable<boolean> {
    if (Capacitor.getPlatform() === 'ios') {
      return from(TextZoom.getPreferred()).pipe(
        // eslint-disable-next-line no-console
        tap(e => console.log('Zoom', e.value)),
        map(zoom =>
          // Event that triggers when screen width passes 24rem
          window.matchMedia(`(max-width: ${24 * 16 * zoom.value}px)`)
        ),
        mergeMap(mediaQuery =>
          // why fromEventPattern? https://github.com/ReactiveX/rxjs/issues/4749
          fromEventPattern<MediaQueryListEvent>(
            mediaQuery.addListener.bind(mediaQuery),
            mediaQuery.removeListener.bind(mediaQuery)
          ).pipe(startWith(mediaQuery))
        ),
        // eslint-disable-next-line no-console
        tap(e => console.log('Matches', e.matches)),
        // true if screen width is less than 24rem
        map(e => e.matches)
      );
    } else {
      return of(false);
    }
  }
}
