import { DocumentService } from './../../services/document.service';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BasicDocument } from '@flink-legacy/core/declarations/document.interface';
import { ModalService } from '@bling-fe/shared/modals/base-modal';
import {
  OptionsModalAction,
  SharedModalsUiOptionsModalComponent,
} from '@flink-legacy/modals/shared-modals-ui-options-modal/shared-modals-ui-options-modal.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DocumentImageComponent } from '../document-image/document-image.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { PdfModalComponent } from '@flink-legacy/modals/pdf-modal/pdf-modal.component';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'fl-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DocumentImageComponent,
    IonicModule,
    TranslateModule,
    NgIf,
    SvgIconComponent,
  ],
})
export class DocumentItemComponent {
  @Input() item: BasicDocument;
  @Input() withPadding = false;
  @Input() showOptions = false;

  constructor(
    private modalService: ModalService,
    private documentService: DocumentService
  ) {}

  openDocument() {
    if (this.item.content_type === 'application/pdf') {
      this.modalService.presentAndGetResult(PdfModalComponent, {
        pdfUrl: this.item.url,
        title: this.item.title,
      });
    } else if (Capacitor.isNativePlatform()) {
      Browser.open({ url: this.item.url });
    } else {
      window.open(this.item.url, '_blank');
    }
  }

  handleOptions(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showAndHandleOptions();
  }

  private async showAndHandleOptions() {
    const actionsEnabled: OptionsModalAction[] = ['delete'];

    const res = await this.modalService.presentAndGetResult(
      SharedModalsUiOptionsModalComponent,
      {
        title: _('MODALS.OPTIONS.DOCUMENT.TITLE'),
        deleteModalProps: {
          title: _('MODALS.OPTIONS.DOCUMENT.DELETE_TITLE'),
          description: _('MODALS.OPTIONS.DOCUMENT.DELETE_DESCRIPTION'),
        },
        actionsEnabled,
      }
    );

    if (res && res.action === 'delete') {
      this.documentService.delete(Number(this.item.id)).subscribe(() => {});
    }
  }
}
