import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { LinkyModule } from 'ngx-linky';
import { IonicModule } from '@ionic/angular';
import { TranslationModule } from '@flink-legacy/core/translation/translation.module';
import { PipesModule } from '../pipes/pipes.module';
import { AttachmentComponent } from './attachment/attachment.component';
import { CommentInputComponent } from './comment-input/comment-input.component';
import { CommentItemComponent } from './comments/comment-item/comment-item.component';
import { CommentsComponent } from './comments/comments.component';
import { FilterChipBarComponent } from './filter-chip-bar/filter-chip-bar.component';
import { DatetimeComponent } from './forms/datetime/datetime.component';
import { SelectComponent } from './forms/select/select.component';
import { GalleryPreviewComponent } from './gallery-preview/gallery-preview.component';
import { HorizontalScrollComponent } from './horizontal-scroll/horizontal-scroll.component';
import { LogoComponent } from './logo/logo.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { UploadPicturesComponent } from './upload-pictures/upload-pictures.component';
import { VideoPreviewComponent } from './video-preview/video-preview.component';
import { MarkdownCheatSheetPreviewComponent } from './markdown-cheat-sheet-preview/markdown-cheat-sheet-preview.component';
import { WarningCardComponent } from './warning-card/warning-card.component';
import { CalendarModule } from 'ion2-calendar';
import { FileComponent } from './forms/file/file.component';
import { RouterModule } from '@angular/router';
import { MenuListComponent } from './menu-list/menu-list.component';
import { HelpTipBoxComponent } from './help-tip-box/help-tip-box.component';
import { DocumentItemComponent } from './document-item/document-item.component';
import { DocumentImageComponent } from './document-image/document-image.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { EmptyBoxComponent } from './empty-box/empty-box.component';
import { ErrorBoxComponent } from './error-box/error-box.component';
import { FormHeaderComponent } from './forms/form-header/form-header.component';
import { DirectivesModule } from '../directives/directives.module';
import { DateRangeComponent } from './forms/date-range/date-range.component';
import { PicturesComponent } from './forms/pictures/pictures.component';
import { DocumentsInputComponent } from './forms/documents-input/documents-input.component';
import { MarkdownHintComponent } from './markdown-hint/markdown-hint.component';
import { PasswordInputComponent } from './forms/password-input/password-input.component';
import { TermsAndConditionsComponent } from './forms/terms-and-conditions/terms-and-conditions.component';
import { MessageListComponent } from './instant-messaging/message-list/message-list.component';
import { MessageItemComponent } from './instant-messaging/message-item/message-item.component';
import { ConversationItemComponent } from './instant-messaging/conversation-item/conversation-item.component';
import { UiNotificationsBadgeComponent } from '@bling-fe/shared/ui-components/ui-notifications-badge';
import { UiAvatarComponent } from '@bling-fe/shared/ui-components/ui-avatar';
import { AvatarComponent } from './avatar/avatar.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslationModule,
    LinkyModule,
    PipesModule,
    DirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    RouterModule,
    SvgIconComponent,
    SkeletonComponent,
    ErrorBoxComponent,
    WarningCardComponent,
    EmptyBoxComponent,
    LogoComponent,
    SelectComponent,
    DocumentItemComponent,
    DocumentImageComponent,
    CommentItemComponent,
    CommentsComponent,
    CommentInputComponent,
    UiNotificationsBadgeComponent,
    UiAvatarComponent,
    AvatarComponent,
  ],
  declarations: [
    AttachmentComponent,
    HorizontalScrollComponent,
    FilterChipBarComponent,
    ReadMoreComponent,
    GalleryPreviewComponent,
    VideoPreviewComponent,
    UploadPicturesComponent,
    MarkdownCheatSheetPreviewComponent,
    DatetimeComponent,
    FileComponent,
    MenuListComponent,
    HelpTipBoxComponent,
    FormHeaderComponent,
    DateRangeComponent,
    PicturesComponent,
    DocumentsInputComponent,
    MarkdownHintComponent,
    PasswordInputComponent,
    TermsAndConditionsComponent,
    MessageListComponent,
    MessageItemComponent,
    ConversationItemComponent,
  ],
  exports: [
    AttachmentComponent,
    LogoComponent,
    HorizontalScrollComponent,
    FilterChipBarComponent,
    ReadMoreComponent,
    LinkyModule,
    GalleryPreviewComponent,
    SelectComponent,
    VideoPreviewComponent,
    CommentsComponent,
    CommentItemComponent,
    CommentInputComponent,
    UploadPicturesComponent,
    MarkdownCheatSheetPreviewComponent,
    DatetimeComponent,
    WarningCardComponent,
    FileComponent,
    MenuListComponent,
    HelpTipBoxComponent,
    DocumentItemComponent,
    DocumentImageComponent,
    SkeletonComponent,
    EmptyBoxComponent,
    ErrorBoxComponent,
    FormHeaderComponent,
    DateRangeComponent,
    PicturesComponent,
    DocumentsInputComponent,
    MarkdownHintComponent,
    PasswordInputComponent,
    TermsAndConditionsComponent,
    MessageListComponent,
    MessageItemComponent,
    ConversationItemComponent,
    AvatarComponent,
  ],
})
export class ComponentsModule {}
